<template>
  <div class="about">
    <h1>This is the Management Page here</h1>
    <div v-for='person in persons'>
    	{{ person.name }}
    </div>
  </div>

</template>

<script>
import * as mydata from '../../assets/misc/management-data.js'

export default {
  name: 'Management',
  data() {
  	return {
  		persons: mydata.PEOPLE
  	}
  },
}
</script>